<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4" class="cd-r-outline">
        <v-card flat>
          <v-list>
            <v-subheader class="mb-2">
              {{ $trans("help_center_title") }}
            </v-subheader>
            <v-list-group
              v-for="(group, index) in groups"
              :key="group.id"
              no-action
              :value="index === 0"
              color="dark"
              :prepend-icon="group.icon"
            >
              <template #activator>
                <v-list-item-title class="text-h6">
                  {{ group.title }}
                </v-list-item-title>
              </template>
              <v-list-item
                v-for="video in group.videos"
                :key="video.url"
                color="dark"
                :class="{
                  'selected-video': selectedVideo.url === video.url,
                }"
                @click="selectedVideo = video"
              >
                {{ video.title }}
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" class="d-flex justify-center align-center">
        <div class="text-center mt-4 pa-md-8 video-wrapper">
          <h5 class="text-h5">
            {{ selectedVideo.title }}
          </h5>
          <p class="mb-4 mt-4 pa-4 text-body-2">
            {{ selectedVideo.description }}
          </p>
          <youtube
            player-width="100%"
            :player-height="getVideoHeight"
            :video-id="selectedVideo.url"
            :class="{ 'rounded-xl': $vuetify.breakpoint.smAndUp }"
            @ready="ready"
            @playing="playing"
          />
          <div v-if="selectedVideo.articles.length > 0" class="mt-8">
            <span class="text-h6">{{ $trans("related_articles") }}:</span><br />
            <a
              v-for="article in selectedVideo.articles"
              :key="article.url[currentLocale]"
              :href="article.url[currentLocale]"
              :title="article.title"
              target="_blank"
              class="text-body-2 text-decoration-none"
            >
              {{ article.title }}<br />
            </a>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "HelpCenterDialog",
  mixins: [sharedActions],
  data() {
    return {
      selectedVideo: {
        url: "TsWyWYvPiJs",
        title: this.$trans("help_center_group_1_video_1_title"),
        description: this.$trans("help_center_group_1_video_1_description"),
        articles: [
          {
            url: {
              pl: "https://calendesk.pl/help/article/platforma-calendesk-przeglad/",
              en: "https://calendesk.com/help/article/calendesk-platform-an-overview/",
            },
            title: this.$trans("article_1_title"),
          },
        ],
      },
      isLoading: false,
      player: null,
      groups: [
        {
          id: 1,
          title: this.$trans("help_center_group_1"),
          icon: "$home",
          videos: [
            {
              url: "TsWyWYvPiJs",
              title: this.$trans("help_center_group_1_video_1_title"),
              description: this.$trans(
                "help_center_group_1_video_1_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/platforma-calendesk-przeglad/",
                    en: "https://calendesk.com/help/article/calendesk-platform-an-overview/",
                  },
                  title: this.$trans("article_1_title"),
                },
              ],
            },
            {
              url: "oDV8I0ClDJQ",
              title: this.$trans("help_center_group_1_video_2_title"),
              description: this.$trans(
                "help_center_group_1_video_2_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/profil-twoja-firma/",
                    en: "https://calendesk.pl/help/article/profil-twoja-firma/", // TODO:
                  },
                  title: this.$trans("article_2_title"),
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: this.$trans("help_center_group_2"),
          icon: "$ticket",
          videos: [
            {
              url: "eztJ7IGbNmI",
              title: this.$trans("help_center_group_2_video_1_title"),
              description: this.$trans(
                "help_center_group_2_video_1_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/pracownicy-dodawanie-pracownika/",
                    en: "https://calendesk.com/help/article/employees-adding-an-employee/",
                  },
                  title: this.$trans("article_3_title"),
                },
              ],
            },
            {
              url: "hePNHqGmYN4",
              title: this.$trans("help_center_group_2_video_2_title"),
              description: this.$trans(
                "help_center_group_2_video_2_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/uslugi-dodaj-kategorie/",
                    en: "https://calendesk.com/help/",
                  },
                  title: this.$trans("article_4_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/uslugi-dodaj-usluge/",
                    en: "https://calendesk.pl/help/article/uslugi-dodaj-usluge/", // TODO:
                  },
                  title: this.$trans("article_5_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/uslugi-lista-uslug/",
                    en: "https://calendesk.pl/help/article/uslugi-lista-uslug/", // TODO:
                  },
                  title: this.$trans("article_6_title"),
                },
              ],
            },
            {
              url: "wNy1IjxG3EY",
              title: this.$trans("help_center_group_2_video_3_title"),
              description: this.$trans(
                "help_center_group_2_video_3_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/uslugi-lista-uslug/",
                    en: "https://calendesk.pl/help/article/uslugi-lista-uslug/", // TODO
                  },
                  title: this.$trans("article_6_title"),
                },
              ],
            },
            {
              url: "mr5P8CX4Nz0",
              title: this.$trans("help_center_group_2_video_4_title"),
              description: this.$trans(
                "help_center_group_2_video_4_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/pracownicy-kalendarz/",
                    en: "https://calendesk.com/help/article/employees-calendar/",
                  },
                  title: this.$trans("article_7_title"),
                },
              ],
            },
            {
              url: "ZrICkw6eD-g",
              title: this.$trans("help_center_group_2_video_5_title"),
              description: this.$trans(
                "help_center_group_2_video_5_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/pracownicy-kalendarz/",
                    en: "https://calendesk.com/help/article/employees-calendar/",
                  },
                  title: this.$trans("article_7_title"),
                },
              ],
            },
            {
              url: "ZWw55-gu4S8",
              title: this.$trans("help_center_group_2_video_6_title"),
              description: this.$trans(
                "help_center_group_2_video_6_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/pracownicy-kalendarz/",
                    en: "https://calendesk.com/help/article/employees-calendar/",
                  },
                  title: this.$trans("article_7_title"),
                },
              ],
            },
          ],
        },
        {
          id: 3,
          title: this.$trans("help_center_group_3"),
          icon: "$account-multiple",
          videos: [
            {
              url: "Aj8ImunBO20",
              title: this.$trans("help_center_group_3_video_1_title"),
              description: this.$trans(
                "help_center_group_3_video_1_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/klienci-lista-klientow/",
                    en: "https://calendesk.com/help/article/customers-list-of-customers/",
                  },
                  title: this.$trans("article_8_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/klienci-dodaj-klienta/",
                    en: "https://calendesk.com/help/article/customers-add-a-customer/",
                  },
                  title: this.$trans("article_9_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/klienci-rezerwacje/",
                    en: "https://calendesk.com/help/article/customers-bookings/",
                  },
                  title: this.$trans("article_10_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/klienci-newsletter/",
                    en: "https://calendesk.com/help/article/customers-newsletter/",
                  },
                  title: this.$trans("article_11_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/klienci-powiadomienia/",
                    en: "https://calendesk.com/help/article/customers-notifications/",
                  },
                  title: this.$trans("article_12_title"),
                },
              ],
            },
            {
              url: "SGUsY-Zof1c",
              title: this.$trans("help_center_group_3_video_2_title"),
              description: this.$trans(
                "help_center_group_3_video_2_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/ustawienia-powiadomienia/",
                    en: "https://calendesk.com/help/article/settings-notifications/",
                  },
                  title: this.$trans("article_13_title"),
                },
              ],
            },
          ],
        },
        {
          id: 4,
          title: this.$trans("help_center_group_4"),
          icon: "$application",
          videos: [
            {
              url: "iH8gQGKes38",
              title: this.$trans("help_center_group_4_video_1_title"),
              description: this.$trans(
                "help_center_group_4_video_1_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/dodatki-strona-www/",
                    en: "https://calendesk.com/help/article/add-ons-website/",
                  },
                  title: this.$trans("article_14_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/strona-www-personalizacja/",
                    en: "https://calendesk.com/help/",
                  },
                  title: this.$trans("article_15_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/strona-www-domena/",
                    en: "https://calendesk.com/help/article/website-domain/",
                  },
                  title: this.$trans("article_16_title"),
                },
              ],
            },
            {
              url: "3qltYEG186Q",
              title: this.$trans("help_center_group_4_video_2_title"),
              description: this.$trans(
                "help_center_group_4_video_2_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/dodatki-strona-www/",
                    en: "https://calendesk.com/help/article/add-ons-website/",
                  },
                  title: this.$trans("article_14_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/strona-www-personalizacja/",
                    en: "https://calendesk.com/help/article/website-personalization/",
                  },
                  title: this.$trans("article_15_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/strona-www-domena/",
                    en: "https://calendesk.com/help/article/website-domain/",
                  },
                  title: this.$trans("article_16_title"),
                },
              ],
            },
            {
              url: "ObSMPOSw53w",
              title: this.$trans("help_center_group_4_video_3_title"),
              description: this.$trans(
                "help_center_group_4_video_3_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/dodatki-strona-www/",
                    en: "https://calendesk.com/help/article/add-ons-website/",
                  },
                  title: this.$trans("article_14_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/strona-www-personalizacja/",
                    en: "https://calendesk.com/help/article/website-personalization/",
                  },
                  title: this.$trans("article_15_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/strona-www-domena/",
                    en: "https://calendesk.com/help/article/website-domain/",
                  },
                  title: this.$trans("article_16_title"),
                },
              ],
            },
            {
              url: "Qw4sJBei_tw",
              title: this.$trans("help_center_group_4_video_4_title"),
              description: this.$trans(
                "help_center_group_4_video_4_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/dodatki-strona-www/",
                    en: "https://calendesk.com/help/article/add-ons-website/",
                  },
                  title: this.$trans("article_14_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/strona-www-personalizacja/",
                    en: "https://calendesk.com/help/",
                  },
                  title: this.$trans("article_15_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/strona-www-domena/",
                    en: "https://calendesk.com/help/article/website-domain/",
                  },
                  title: this.$trans("article_16_title"),
                },
              ],
            },
            {
              url: "pRNPdM0Dc5c",
              title: this.$trans("help_center_group_4_video_5_title"),
              description: this.$trans(
                "help_center_group_4_video_5_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/dodatki-strona-www/",
                    en: "https://calendesk.com/help/article/add-ons-website/",
                  },
                  title: this.$trans("article_14_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/strona-www-personalizacja/",
                    en: "https://calendesk.com/help/article/website-personalization/",
                  },
                  title: this.$trans("article_15_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/strona-www-domena/",
                    en: "https://calendesk.com/help/article/website-domain/",
                  },
                  title: this.$trans("article_16_title"),
                },
              ],
            },
            {
              url: "nms_sJL6pxM",
              title: this.$trans("help_center_group_4_video_6_title"),
              description: this.$trans(
                "help_center_group_4_video_6_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/dodatki-strona-www/",
                    en: "https://calendesk.com/help/article/add-ons-website/",
                  },
                  title: this.$trans("article_14_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/strona-www-personalizacja/",
                    en: "https://calendesk.com/help/article/website-personalization/",
                  },
                  title: this.$trans("article_15_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/strona-www-domena/",
                    en: "https://calendesk.com/help/article/website-domain/",
                  },
                  title: this.$trans("article_16_title"),
                },
              ],
            },
          ],
        },
        {
          id: 5,
          title: this.$trans("help_center_group_5"),
          icon: "$mobile",
          videos: [
            {
              url: "RW0bKZTWNa4",
              title: this.$trans("help_center_group_5_video_1_title"),
              description: this.$trans(
                "help_center_group_5_video_1_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/dodatki-aplikacja-mobilna/",
                    en: "https://calendesk.com/help/article/add-ons-mobile-application/",
                  },
                  title: this.$trans("article_17_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/dodatki-aplikacja-mobilna-personalizacja/",
                    en: "https://calendesk.com/help/article/add-ons-mobile-application-personalization/",
                  },
                  title: this.$trans("article_18_title"),
                },
              ],
            },
            {
              url: "qq_03PrtiUo",
              title: this.$trans("help_center_group_5_video_2_title"),
              description: this.$trans(
                "help_center_group_5_video_2_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/dodatki-aplikacja-mobilna/",
                    en: "https://calendesk.com/help/article/add-ons-mobile-application/",
                  },
                  title: this.$trans("article_17_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/dodatki-aplikacja-mobilna-personalizacja/",
                    en: "https://calendesk.com/help/article/add-ons-mobile-application-personalization/",
                  },
                  title: this.$trans("article_18_title"),
                },
              ],
            },
          ],
        },
        {
          id: 6,
          title: this.$trans("help_center_group_6"),
          icon: "$card",
          videos: [
            {
              url: "FX63_ZUovIA",
              title: this.$trans("help_center_group_6_video_1_title"),
              description: this.$trans(
                "help_center_group_6_video_1_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/dodatki-platnosci-stripe/",
                    en: "https://calendesk.pl/help/article/dodatki-platnosci-stripe/", // TODO:
                  },
                  title: this.$trans("article_19_title"),
                },
              ],
            },
            {
              url: "BsChi03jq7c",
              title: this.$trans("help_center_group_6_video_2_title"),
              description: this.$trans(
                "help_center_group_6_video_2_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/dodatki-platnosci-stripe/",
                    en: "https://calendesk.pl/help/article/dodatki-platnosci-stripe/", // TODO:
                  },
                  title: this.$trans("article_19_title"),
                },
              ],
            },
            {
              url: "X97F39bgSyA",
              title: this.$trans("help_center_group_6_video_3_title"),
              description: this.$trans(
                "help_center_group_6_video_3_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/dodatki-platnosci-stripe/",
                    en: "https://calendesk.pl/help/article/dodatki-platnosci-stripe/", // TODO:
                  },
                  title: this.$trans("article_19_title"),
                },
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/uslugi-dodaj-usluge/",
                    en: "https://calendesk.pl/help/article/uslugi-dodaj-usluge/", // TODO:
                  },
                  title: this.$trans("article_5_title"),
                },
              ],
            },
            {
              url: "uDHEF-TnuEI",
              title: this.$trans("help_center_group_6_video_4_title"),
              description: this.$trans(
                "help_center_group_6_video_4_description"
              ),
              articles: [
                {
                  url: {
                    pl: "https://calendesk.pl/help/article/dodatki-platnosci-stripe/",
                    en: "https://calendesk.pl/help/article/dodatki-platnosci-stripe/", // TODO:
                  },
                  title: this.$trans("article_19_title"),
                },
              ],
            },
          ],
        },
      ],
    };
  },
  computed: {
    currentLocale() {
      return localStorage.locale;
    },
    getVideoHeight() {
      if (this.$vuetify.breakpoint.width <= 600) {
        return 250;
      }
      if (this.$vuetify.breakpoint.width <= 1024) {
        return 320;
      }
      if (this.$vuetify.breakpoint.width > 2000) {
        return 720;
      }

      return 380;
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.stopVideo();
    }
  },
  methods: {
    ready(event) {
      this.player = event.target;
    },
    playing(event) {},
    stop() {
      if (this.player) {
        this.player.stopVideo();
      }
    },
    pause() {
      this.player.pauseVideo();
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-video {
  background: var(--v-accent-base) !important;
}

.video-wrapper {
  width: 100%;
}
</style>
